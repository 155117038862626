import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import "../styles/App.css";

const Welcome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="calc-container">
      <h1>{t("title")}</h1>
      <h2>{t("welcome_0")}</h2>
      <div className="section-content">
        <p>{t("welcome_1_0")}</p>
        <ul>
          <li>
            <strong>{t("welcome_1_1")}</strong>
            {t("welcome_1_2")}
          </li>
          <li>
            <strong>{t("welcome_1_3")}</strong>
            {t("welcome_1_4")}
          </li>
        </ul>
        <p>{t("welcome_1_5")}</p>
        <ol>
          <li>{t("welcome_1_6")}</li>
          <li>{t("welcome_1_7")}</li>
          <li>{t("welcome_1_8")}</li>
        </ol>
        <p>{t("welcome_2_0")}</p>
      </div>

      <div className="button-wrapper">
        <button
          className="back-next-button"
          onClick={() => history.push("/llar")}
        >
          Calcular
          <FontAwesomeIcon
            icon={faCalculator}
            className="back-next-button-icon-right"
          />
        </button>
      </div>
    </div>
  );
};

export default Welcome;
