import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faCirclePlus as faUserPlus,
  faCircleMinus as faUserMinus,
} from "@fortawesome/free-solid-svg-icons";
import "../styles/App.css";
import "../styles/Button.css";
import "../styles/ToggleSwitch.css";
import { API_URL } from "../constants.js";

const Step2 = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [economicSubsidies, setEconomicSubsidies] = useState([]);
  const [mealSubsidies, setMealSubsidies] = useState([]);

  useEffect(() => {
    const storedEconomicSubsidies = JSON.parse(
      localStorage.getItem("economicSubsidies")
    );
    if (storedEconomicSubsidies) {
      setEconomicSubsidies(storedEconomicSubsidies);
    }
  }, []);

  useEffect(() => {
    const storedMealSubsidies = JSON.parse(
      localStorage.getItem("mealSubsidies")
    );
    if (storedMealSubsidies) {
      setMealSubsidies(storedMealSubsidies);
    }
  }, []);

  const handleEconomicSubsidyAmountChange = (event, index) => {
    const { value } = event.target;
    const updatedEconomicSubsidies = [...economicSubsidies];
    updatedEconomicSubsidies[index].amount = parseInt(value);
    setEconomicSubsidies(updatedEconomicSubsidies);
  };

  const handleEconomicSubsidySelectedFrequencyChange = (event, index) => {
    const { value } = event.target;
    const updatedEconomicSubsidies = [...economicSubsidies];
    updatedEconomicSubsidies[index].frequency = value;
    setEconomicSubsidies(updatedEconomicSubsidies);
  };

  const handleAddEconomicSubsidy = () => {
    setEconomicSubsidies([
      ...economicSubsidies,
      { amount: "", frequency: "", showError: false },
    ]);
  };

  const handleRemoveEconomicSubsidy = (index) => {
    const updatedEconomicSubsidies = [...economicSubsidies];
    updatedEconomicSubsidies.splice(index, 1);
    setEconomicSubsidies(updatedEconomicSubsidies);
  };

  const handleMealSubsidyChange = (event, index) => {
    const { value } = event.target;
    const updatedMealSubsidies = [...mealSubsidies];
    updatedMealSubsidies[index].item = value;
    updatedMealSubsidies[index].amount = predefinedMeals.find(
      (option) => option.item === value
    )?.amount;
    updatedMealSubsidies[index].frequency = predefinedMeals.find(
      (option) => option.item === value
    )?.frequency;
    setMealSubsidies(updatedMealSubsidies);
  };

  const handleAddMealSubsidy = () => {
    setMealSubsidies([
      ...mealSubsidies,
      { item: "", amount: "", frequency: "", showError: false },
    ]);
  };

  const handleRemoveMealSubsidy = (index) => {
    const updatedMealSubsidies = [...mealSubsidies];
    updatedMealSubsidies.splice(index, 1);
    setMealSubsidies(updatedMealSubsidies);
  };

  const handleNext = () => {
    const updatedEconomicSubsidies = economicSubsidies.map((group) => ({
      ...group,
      showError: !(group.amount && group.frequency),
    }));
    setEconomicSubsidies(updatedEconomicSubsidies);

    let allGood = true;
    if (
      updatedEconomicSubsidies.every((group) => group.amount && group.frequency)
    ) {
      localStorage.setItem(
        "economicSubsidies",
        JSON.stringify(updatedEconomicSubsidies)
      );
    } else {
      allGood = false;
      alert(t("step2_alert_0"));
    }

    const updatedMealSubsidies = mealSubsidies.map((group) => ({
      ...group,
      showError: !group.amount,
    }));
    setMealSubsidies(updatedMealSubsidies);

    if (updatedMealSubsidies.every((group) => group.amount)) {
      localStorage.setItem(
        "mealSubsidies",
        JSON.stringify(updatedMealSubsidies)
      );
    } else {
      allGood = false;
      alert(t("step2_alert_1"));
    }

    if (allGood) {
      history.push("/resultats");
    }
  };

  const predefinedFrequencies = ["7", "30"];
  const [predefinedMeals, setPredefinedMeals] = useState([]);

  useEffect(() => {
    fetch(API_URL + "/?meals=true")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch predefinedMeals");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched predefinedMeals:", data);
        setPredefinedMeals(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching predefinedMeals:", error);
      });
  }, []);

  return (
    <div className="calc-container">
      <h1>{t("title")}</h1>
      <h2>{t("step2_0_1")}</h2>
      <div className="section-content">
        <p>{t("step2_0_2")}</p>

        <div className="subsidy-wrapper">
          {economicSubsidies.map((group, index) => (
            <div
              key={index}
              className={`input-wrapper ${
                group.showError ? "missing-info" : ""
              }`}
            >
              {" "}
              <div className="input-subsidy-subwrapper">
                <div className="input-subwrapper">
                  {/* <label>{t("step2_1_0")}</label> */}
                  <div className="currency-input-wrapper">
                    <input
                      type="number"
                      name="amount"
                      value={group.amount}
                      min="1"
                      step="1"
                      onChange={(event) =>
                        handleEconomicSubsidyAmountChange(event, index)
                      }
                      className="currency-input"
                    />
                    <span className="currency-symbol">€</span>
                  </div>
                  {/* </div> */}
                  {/* <div className="input-subwrapper"> */}
                  <label>{t("step2_1_1")}</label>
                  <select
                    className="frequency-select"
                    value={group.frequency}
                    onChange={(event) =>
                      handleEconomicSubsidySelectedFrequencyChange(event, index)
                    }
                  >
                    <option value="">{t("step2_1_2")}</option>
                    {predefinedFrequencies.map((option, optionIndex) => (
                      <option key={optionIndex} value={option}>
                        {t(`step2_freq_${option}`)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                className="remove-button"
                onClick={() => handleRemoveEconomicSubsidy(index)}
              >
                <FontAwesomeIcon
                  icon={faUserMinus}
                  className="back-next-button-icon-right"
                />
              </button>
            </div>
          ))}

          <button className="add-button" onClick={handleAddEconomicSubsidy}>
            <FontAwesomeIcon
              icon={faUserPlus}
              className="back-next-button-icon-left"
            />
            {t("step2_1_3")}
          </button>
        </div>

        <div className="subsidy-wrapper">
          {mealSubsidies.map((group, index) => (
            <div
              key={index}
              className={`input-wrapper ${
                group.showError ? "missing-info" : ""
              }`}
            >
              {isLoading ? (
                <div></div>
              ) : (
                <div className="input-subsidy-subwrapper">
                  <div className="input-subwrapper">
                    <select
                      className="meal-select"
                      value={group.item}
                      onChange={(event) =>
                        handleMealSubsidyChange(event, index)
                      }
                    >
                      <option value="">{t("step2_2_1")}</option>
                      {predefinedMeals.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.item}>
                          {option.item}
                        </option>
                      ))}
                    </select>
                    {group.item && (
                      <label>
                        {
                          predefinedMeals.find(
                            (option) => option.item === group.item
                          )?.amount
                        }
                        {" € " + t("step2_1_1") + " "}
                        {t(
                          `step2_freq_${
                            predefinedMeals.find(
                              (option) => option.item === group.item
                            )?.frequency
                          }`
                        )}
                      </label>
                    )}
                  </div>
                </div>
              )}
              <button
                className="remove-button"
                onClick={() => handleRemoveMealSubsidy(index)}
              >
                <FontAwesomeIcon
                  icon={faUserMinus}
                  className="back-next-button-icon-right"
                />
              </button>
            </div>
          ))}

          <button className="add-button" onClick={handleAddMealSubsidy}>
            <FontAwesomeIcon
              icon={faUserPlus}
              className="back-next-button-icon-left"
            />
            {t("step2_2_2")}
          </button>
        </div>
      </div>
      <div className="button-wrapper">
        <button className="back-next-button" onClick={() => history.goBack()}>
          {t("step1_2_1")}
        </button>
        <button className="back-next-button" onClick={handleNext}>
          {t("step2_3_0")}
          <FontAwesomeIcon
            icon={faChartSimple}
            className="back-next-button-icon-right"
          />
        </button>
      </div>
    </div>
  );
};

export default Step2;
