import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddMemberIcon } from "../icons/member-add.svg";
import { ReactComponent as RemoveMemberIcon } from "../icons/member-remove.svg";
import "../styles/App.css";
import "../styles/Button.css";

const Step1 = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [familyMembers, setFamilyMembers] = useState([
    { gender: "", age: "", showError: false },
  ]);

  useEffect(() => {
    const storedFamilyMembers = JSON.parse(localStorage.getItem("family"));
    if (storedFamilyMembers) {
      setFamilyMembers(storedFamilyMembers);
    }
  }, []);

  const handleSelectedAgeGroupChange = (event, index) => {
    const { value } = event.target;
    const updatedFamilyMembers = [...familyMembers];
    updatedFamilyMembers[index].age = value;
    setFamilyMembers(updatedFamilyMembers);
  };

  const handleAddFamilyMember = () => {
    setFamilyMembers([
      ...familyMembers,
      { gender: "", age: "", showError: false },
    ]);
  };

  const handleRemoveFamilyMember = (index) => {
    const updatedFamilyMembers = [...familyMembers];
    updatedFamilyMembers.splice(index, 1);
    setFamilyMembers(updatedFamilyMembers);
  };

  const handleNext = () => {
    const updatedFamilyMembers = familyMembers.map((group) => ({
      ...group,
      showError: !group.age,
    }));
    setFamilyMembers(updatedFamilyMembers);

    if (updatedFamilyMembers.every((group) => group.age)) {
      localStorage.setItem("family", JSON.stringify(updatedFamilyMembers));
      history.push("/ajudes");
    } else {
      alert(t("step1_alert"));
    }
  };

  const predefinedAgeGroups = ["65", "30_60", "18_29", "14", "10", "6", "2_5"];

  return (
    <div className="calc-container">
      <h1>{t("title")}</h1>
      <h2>{t("step1_0_1")}</h2>
      <div className="section-content">
        <p>{t("step1_0_2")}</p>

        {familyMembers.map((group, index) => (
          <div
            key={index}
            className={`input-wrapper ${group.showError ? "missing-info" : ""}`}
          >
            <div className="input-subwrapper">
              <label>{t("step1_1_0")}</label>
              <select
                className="age-select"
                value={group.age}
                onChange={(event) => handleSelectedAgeGroupChange(event, index)}
              >
                <option value="">{t("step1_1_1")}</option>
                {predefinedAgeGroups.map((option, optionIndex) => (
                  <option key={optionIndex} value={option}>
                    {t(`step1_age_${option}`)}
                  </option>
                ))}
              </select>
            </div>
            {familyMembers.length > 1 && (
              <button
                className="remove-button"
                onClick={() => handleRemoveFamilyMember(index)}
              >
                <RemoveMemberIcon className="svg-icon" />
              </button>
            )}
          </div>
        ))}

        <button className="add-button" onClick={handleAddFamilyMember}>
          <AddMemberIcon className="svg-icon" />
          {t("step1_2_0")}
        </button>
      </div>
      <div className="button-wrapper">
        <button className="back-next-button" onClick={() => history.goBack()}>
          {t("step1_2_1")}
        </button>
        <button className="back-next-button" onClick={handleNext}>
          {t("step1_2_2")}
        </button>
      </div>
    </div>
  );
};

export default Step1;
