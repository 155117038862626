import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ReactComponent as FileIcon } from "../icons/file.svg";
import "../styles/App.css";
import "../styles/Button.css";
import "../styles/Results.css";
import { API_URL } from "../constants.js";

const getValues = async (query) => {
  const response = await fetch(API_URL, {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

const Results = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const step1Values = JSON.parse(localStorage.getItem("family"));
  const step2aValues = JSON.parse(localStorage.getItem("economicSubsidies"));
  const step2bValues = JSON.parse(localStorage.getItem("inKindSubsidies"));
  const step2cValues = JSON.parse(localStorage.getItem("mealSubsidies"));

  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  step1Values.forEach((member) => {
    delete member.showError;
  });
  step2aValues.forEach((subsidy) => {
    delete subsidy.showError;
  });
  step2cValues.forEach((subsidy) => {
    delete subsidy.showError;
  });

  const query = {
    family: step1Values,
    subsidies: {
      economic: step2aValues === null ? [] : step2aValues,
      inKind: step2bValues === null ? [] : step2bValues,
      meal: step2cValues === null ? [] : step2cValues,
    },
  };

  const fetchValues = async () => {
    const values = await getValues(query);
    // console.log(values);
    setJsonData(values);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchValues();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const groupedData = jsonData.reduce((groups, item) => {
    if (!groups[item.buying_frequency]) {
      groups[item.buying_frequency] = [];
    }
    groups[item.buying_frequency].push(item);
    return groups;
  }, {});

  function displayItemQuantity(quantity, unit) {
    if (quantity >= 1000) {
      quantity = Number((quantity / 1000).toFixed(3)).toString();
      unit = unit === "g" ? " kg" : " l";
    }
    return (
      <>
        {quantity} {unit}
      </>
    );
  }

  const handlePrint = () => {
    window.print();
  };

  // calculate global average ideal cost per day once data is fetched
  useEffect(() => {
    let globalAverageIdealCostPerDay = 0;
    if (jsonData.length > 0) {
      Object.keys(groupedData).forEach((frequency) => {
        const itemsInFrequency = groupedData[frequency];
        const totalCost = itemsInFrequency.reduce(
          (sum, item) => {
            return sum + (item.ideal_cost !== undefined ? item.ideal_cost : 0)
          },
          0 // initial value
        );
        globalAverageIdealCostPerDay += totalCost;
      });
      setIdealGlobalAverage(globalAverageIdealCostPerDay);
    }
  }, [jsonData, groupedData]);

  function ceil_5(x) {
    return Math.ceil(x / 5) * 5;
  }
  function floor_5(x) {
    return Math.floor(x / 5) * 5;
  }

  const [globalIdealAverage, setIdealGlobalAverage] = useState(null);
  const totalEconomicSubsidies =
    step2aValues.reduce(
      (sum, item) => sum + item.amount / parseInt(item.frequency),
      0
    ) +
    step2cValues.reduce(
      (sum, item) => sum + item.amount / parseInt(item.frequency),
      0
    );

  return (
    <div id="print">
      <h2>{t("results_0_0")}</h2>
      <div className="section-content">
        {isLoading ? (
          <div className="loading">
            <div className="placeholder"></div>
          </div>
        ) : (
          <TransitionGroup>
            {globalIdealAverage !== null && (
              <p className="intro">
                {t("results_1_0") + " "}
                <span className="nowrap">
                  {ceil_5(globalIdealAverage * 30).toFixed(0) +
                    " " +
                    t("results_1_1_4")}
                </span>
                {". " + t("results_1_1")}
              </p>
            )}
            {totalEconomicSubsidies > 0 && (
              <div className="economic-subsidy-notice">
                {t("results_1_1_0") + " "}
                <span className="nowrap">
                  {(totalEconomicSubsidies * 30).toFixed(2) +
                    " " +
                    t("results_1_1_4") +
                    ", "}
                </span>
                {t("results_1_1_1") +
                  " " +
                  (Math.min(1, totalEconomicSubsidies / globalIdealAverage) * 100).toFixed(0) +
                  t("results_1_1_2") +
                  " "}
                {t("results_1_1_3") + " "}
                <span className="nowrap">
                  {ceil_5(Math.max(0, globalIdealAverage - totalEconomicSubsidies) * 30).toFixed(0) +
                    " " +
                    t("results_1_1_4") +
                    "."}
                </span>
              </div>
            )}
            <div className="list-content">
              {Object.keys(groupedData).map((frequency) => {
                const itemsInFrequency = groupedData[frequency];
                const totalCost = itemsInFrequency.reduce(
                  (sum, item) =>
                    sum +
                    (item.cost_per_buy !== undefined ? item.cost_per_buy : 0),
                  0
                );

                return (
                  <CSSTransition
                    key={frequency}
                    in={!isLoading}
                    timeout={200}
                    classNames="fade"
                  >
                    <div>
                      <h3>
                        {t("results_2_0") +
                          " " +
                          t(`results_freq_${frequency}`)}
                        : {floor_5(totalCost).toFixed(0)} - {ceil_5(totalCost).toFixed(0)} €
                      </h3>
                      <table>
                        <thead>
                          <tr>
                            <th>{t("results_2_1")}</th>
                            <th>{t("results_2_2")}</th>
                            {/* <th>Cost</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {groupedData[frequency].map((item, index) => (
                            <tr key={index}>
                              <td>{t(item.item)}</td>
                              <td>
                                {(item.format === "Pieces" || item.format === "Bars") ? (
                                  <>
                                    {item.packages_to_buy *
                                      item.pieces_per_package}
                                    {item.packages_to_buy *
                                      item.pieces_per_package ===
                                    1
                                      ? ` ${t(`format_${item.format.toLowerCase()}_0`)}`
                                      : ` ${t(`format_${item.format.toLowerCase()}_1`)}`}
                                  </>
                                ) : (
                                  <>
                                    {item.format === "Bulk" ? (
                                      <>
                                        {displayItemQuantity(
                                          item.packages_to_buy *
                                            item.content_per_package,
                                          item.unit
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {item.packages_to_buy + " "}
                                        {item.packages_to_buy === 1
                                          ? t(`format_${item.format.toLowerCase()}_0`)
                                          : t(`format_${item.format.toLowerCase()}_1`)}
                                        {Math.floor(
                                          item.content_per_package / 1000
                                        ) === 1
                                          ? ` ${t("results_2_6")}`
                                          : ` ${t("results_2_5")} `}
                                        {item.format === "Carton" ? (
                                          <>
                                            {item.pieces_per_package}
                                            {item.pieces_per_package === 1
                                              ? ` ${t(`format_pieces_0`)}`
                                              : ` ${t(`format_pieces_1`)}`}
                                          </>
                                        ) : (
                                          <>
                                          {displayItemQuantity(
                                            item.content_per_package,
                                            item.unit
                                          )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CSSTransition>
                );
              })}
            </div>
          </TransitionGroup>
        )}
        <div className="disclaimer">{t("results_3_0")}</div>
        <div className="disclaimer">{t("results_3_1")}</div>
        <div className="button-wrapper">
          <button className="back-next-button" onClick={() => history.goBack()}>
            {t("step1_2_1")}
          </button>

          <button className="back-next-button" onClick={handlePrint}>
            Imprimir
          </button>
          <a href={"/menus/TardorHivern.pdf"} download="mealpuzzle-menus.pdf">
            <button className="back-next-button">
              Menús
              <FileIcon className="svg-button-icon" />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Results;
