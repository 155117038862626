import React, { useState } from "react";
import i18n from "i18next";
import "./styles/Spinner.css";
import "./styles/Button.css";

const LanguageSwitcher = () => {
  const [loading_ca, setLoading_ca] = useState(false);
  const [loading_es, setLoading_es] = useState(false);

  const setLoading = (lng, loading) => {
    if (lng === "ca") {
      setLoading_ca(loading);
    } else if (lng === "es") {
      setLoading_es(loading);
    }
  };

  const changeLanguage = (lng) => {
    setLoading(lng, true);

    // Change the language and then update the loading state when done
    i18n.changeLanguage(lng, (err) => {
      console.log("Language changed to: " + lng);
      if (!err) {
        setLoading(lng, false);
      } else {
        console.error("Error loading language:", err);
        setLoading(lng, false);
      }
    });
  };

  return (
    <div className="lng-button-wrapper">
      <div className="lng-spinner-wrapper">
        {loading_ca && <div className="lng-spinner"></div>}{" "}
      </div>
      <button className="lng-button" onClick={() => changeLanguage("ca")}>
        Català
      </button>
      <div className="lng-spinner-wrapper">
        {loading_es && <div className="lng-spinner"></div>}{" "}
      </div>
      <button className="lng-button" onClick={() => changeLanguage("es")}>
        Español
      </button>
    </div>
  );
};

export default LanguageSwitcher;
