import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Welcome from "./pages/Welcome";
import Step1 from "./pages/Step1";
import Step2 from "./pages/Step2";
import Results from "./pages/Results";
import LanguageSwitcher from "./languageSwitcher";
import './styles/App.css';
import './styles/Spinner.css';

const InnerApp = () => {

  return (
    <Router>
      <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        <div className="app-header">
          <a href="/"><img src="/logo.svg" alt="logo" className="app-logo" /></a>
          <LanguageSwitcher />
        </div>
        <div className="background-container">
          <div className="app-container">
            <Switch>
              <Route path="/" exact component={Welcome} />
              <Route path="/llar" component={Step1} />
              <Route path="/ajudes" component={Step2} />
              <Route path="/resultats" component={Results} />
            </Switch>
          </div>
          <footer>
            <p>&copy; 2023 Global Research on Wellbeing (GRoW) research group - Facultat de Ciències de la Salut Blanquerna, Universitat Ramon Llull. Tots els drets reservats.</p>
          </footer>
        </div>
      </div>
    </Router>
  );
};

const App = () => {
  return (
    <Suspense fallback={<div className="spinner"></div>}>
      <InnerApp />
    </Suspense>
  );
}

export default App;
